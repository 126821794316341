@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Beautiste';
  src: url('./assets/fonts/Beausite_Classic_Regular.ttf');
}

@layer components {
  .business-dropdown ul,
  .individual-dropdown ul {
    border-radius: 16px;
    padding: 16px;
  }

  input:checked + .product-select {
    background-color: #e4f3fa;
    border: 1px solid theme('colors.primary.4');
    box-shadow: none;
  }

  input:checked + .product-select > .selected-tag {
    display: flex;
  }
}

.ant-switch-checked {
  background-color: theme('colors.primary.4');
}

.slick-track {
  display: flex;
  gap: 1rem;
}

#toggle-switch::after {
  content: '';
  @apply h-5 w-5 rounded-full bg-white shadow absolute left-0 top-0 transform scale-110 transition duration-200;
}

#toggle-switch:checked::after {
  @apply transform scale-110 translate-x-5;
}

body {
  height: 100vh;
  overflow: auto;
}

@import '~antd/dist/antd.css';
