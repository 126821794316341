.slick-dots {
  background-color: #F6FAFD;
  border-radius: 32px;
  width: 100%;
  margin: auto !important;
  position: absolute;
  box-shadow: 0px 2px 8px rgba(0, 28, 61, 0.1);
  left: 50%;
  transform: translate(-50%, -150%);
  opacity: 0.8;
  height: max-content;
  display: none;

}

.slick-track .slick-list{
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .slick-dots {
    height: 10px;
    display: none;
    opacity: 100%;
    padding: 3px;
  }
}

.slick-initialized .slick-slide {
  flex: none;
}

.slick-next:before,
[dir='rtl'] .slick-prev:before {
  content: none;

}

.slick-prev:before {
  content: none;

}


.friday-page .slick-arrow {
  background-color: white;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  display: none;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  opacity: 0.7;


}

@media only screen and (max-width: 700px) {
  .friday-page .slick-arrow {
    height: 20px;
    width: 20px;
    display: none;
    opacity: 100%;
    padding: 3px;
  }
}

.friday-page .slick-prev {
  transform: translate(38px, -50%);
  z-index: 2;
  display: none;
}

.friday-page .slick-next {
  transform: translate(-38px, -50%);
  display: none;
}

.friday-page .slick-dots li {
  transform: translateY(-3px);
  display: none;
}

@media only screen and (max-width: 700px) {
  .friday-page .slick-dots li {
    height: 10px;
    width: 10px;
    display: none;
    opacity: 100%;
    padding: 3px;
  }
}

.friday-page .slick-dots li.slick-active button:before {
  opacity: .75;
  color: #00ADEE;
}